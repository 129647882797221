<template>
  <div id="merchant-exchanges">
    <div class="vx-row mb-4">
      <div class="vx-col w-full lg:w-1/2 mb-4">
        <h2>{{$t('Exchanges')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end">
        <vs-alert class="mb-base" active="true" color="warning" icon-pack="feather" icon="icon-alert-triangle" v-if="merchantStatus === 'on_hold'">
          {{$t('Your account is')}} <b>{{$t('On hold')}}</b> {{$t('due to inactivity. Please, use the chat icon to talk to our support team and reactivate your account.')}}
        </vs-alert>
        <div v-else-if="$store.state.AppActiveUser.userRole === 'merchant' && merchantStatus === 'active'">
          <div class="flex justify-between">
            <span class="font-semibold"></span>
            <span @click="exchangePage()" class="font-medium text-primary cursor-pointer">
              <vs-button
                color="primary"
                icon-pack="feather"
                icon="icon-plus"
                size="large"
              >
              </vs-button>
            </span>
          </div>
        </div>
      </div>
    </div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event"></head-component>
    <shipblu-table
      :sst="true"
      multiple
      orders
      v-model="selected"
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      pagination
      :data="exchanges"
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Order Type')}}</shipblu-th>
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Location')}}</shipblu-th>
        <shipblu-th>{{$t('Created On')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-exchanges`, params: { type: 'exchanges', orderID: data[indextr].id }}">
          <shipblu-td :data="data[indextr].orderType">
            <p  class="mb-3">Return</p>
            <p>Delivery</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="inactive-link">
            <p v-on:click.stop class="mb-2">{{data[indextr].return_order.tracking_number}}</p>
            <p v-on:click.stop>{{data[indextr].delivery_order.tracking_number}}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].customerInfo">
            {{ data[indextr].delivery_order.customer.full_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].location">
            {{ $t(data[indextr].delivery_order.customer.address.zone.name) }} / {{ $t(data[indextr].delivery_order.customer.address.zone.city.name) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].createdOn">
            {{ common.splitYearOfDate(data[indextr].created) }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <div class="m-auto flex items-center justify-center font-semibold">
              <div><span class="status-icon mr-1"></span></div>
              <span class="capitalize">{{ $t(getExchangeStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import HeadComponent from '../merchant/components/Header.vue'
import Shipment from './components/Shipment.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      exchanges: [],
      filters: [],
      statusFilters: [
        {
          name: 'all',
          value: 'all'
        },
        {
          name: 'created',
          value: 'delivery_order_status=created,pickup_requested,out_for_pickup&return_order_status=created,return_requested'
        },
        {
          name: 'picked up to exchange',
          value: 'delivery_order_status=picked_up,in_transit,en_route,out_for_delivery,delivery_attempted&return_order_status=created,return_requested,return_attempted'
        },
        {
          name: 'swapping',
          value: 'delivery_order_status=delivered&return_order_status=picked_up,in_transit,en_route,return_postponed,return_refused'
        },
        {
          name: 'exchanged',
          value: 'delivery_order_status=delivered&return_order_status=returned'
        },
        {
          name: 'failed to exchange',
          value: 'delivery_order_status=cancelled,delivery_attempted&return_order_status=cancelled,return_refused'
        }
      ],
      searchVal: '',
      filter: '',
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchInProgress: false,
      searchedValue: ' ',
      tableLoader: false,
      merchantStatus: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-exchanges-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadExchanges()
    },
    'selected' () {
      this.$emit('selected', this.selected)
    }
  },
  methods: {
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadExchanges()
    },
    exchangePage () {
      this.$router.push({
        name: 'merchant-shipments',
        query: {
          page: 1,
          filter: 'delivered',
          from: 'exchange'
        } 
      }).catch(() => {})
    },
    getExchangeStatusLabel (order) {
      return common.getExchangeStatusLabel(order)
    },
    getStyle (data) {
      return common.getOrderStatusColor(this.getExchangeStatusLabel(data) ? this.getExchangeStatusLabel(data) : '')
    },
    changeFilter () {
      this.filters = ''
      this.filters = this.filterValue
      if (this.filterValue === 'all') {
        this.filters = ''
      }
      this.currentPage = 1
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-exchanges-view`,
        query: {
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadExchanges()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadOrdersSearch)
    },
    loadOrdersSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadExchanges()
    },
    loadExchanges () {
      this.tableLoader = true
      this.searchInProgress = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}&${this.filters}`
      sendRequest(true, false, this, `api/v1/exchanges/${query}`, 'get', null, true, 
        (response) => {
          this.exchanges = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    viewExchange (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-exchanges`,
        params: {
          type: 'exchanges',
          orderID: data.id
        }
      }).catch(() => {})
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    HeadComponent,
    Shipment,
    ShipbluPagination
  },
  created () {
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : ''
    this.offset = (this.currentPage - 1) * this.maximumItems
    if (this.$store.state.AppActiveUser.userRole === 'merchant') {
      const merchantData = common.checkMerchantInLocalStorage(this)
      let user = {}
      merchantData.then(results => {
        user = results.merchantData
        this.merchantStatus = user.status
      })
    }
    this.loadExchanges()
  }
}
</script>

<style>
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
